import styled, {css} from 'styled-components';
import {Link} from 'gatsby';

const ButtonStyle = css`
  background-color: ${props => props.secondary ? 'transparent' : props.theme.colors.compassGreen};
  padding: ${props => props.large ? '18px 29px' : '15px 25px' };
  font-size: ${props => props.large ? props.theme.font.large : props.theme.font.standard};
  color: ${props => props.secondary ? props.theme.colors.darkGreen : 'white'};
  transition: background-color 100ms;
  box-shadow: ${props => props.secondary ? 'none' : props.theme.shadow.standard};
  border-radius: 8px;
  border-style: ${props => props.secondary ? 'solid' : 'none'};
  border-width: ${props => props.secondary ? '2px' : '0px'};
  border-color: ${props => props.secondary ? props.theme.colors.darkGreen : 'transparent'};
  display: inline-block;

  &:hover {
    background-color: ${props => props.secondary ? props.theme.colors.green005 : props.theme.colors.accentCompassGreen};
    color: ${props => props.secondary ? props.theme.colors.darkGreen : 'white'};
    text-decoration: none;
  }
  
  &:after {
    content: '${props => props.secondary ? '→' : ''}';
    font-weight: bold;
    margin-left: ${props => props.secondary ? '10px' : '0px'};
  }
`

const DisabledButtonStyle = css`
  ${ButtonStyle};
  background-color: #cccccc;
  color: #999999;

  &:hover {
    background-color: #cccccc;
    color: #999999;
  }
`

export const ButtonLink = styled(Link)`
  ${props => props.to ? ButtonStyle : DisabledButtonStyle}
`

export const ButtonA = styled.a`
  ${props => props.href ? ButtonStyle : DisabledButtonStyle}
`
