import { createGlobalStyle } from 'styled-components';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/600.css';
import '@fontsource/manrope/700.css';
import '@fontsource/manrope/800.css';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Manrope', sans-serrif;
  }
  html {
    font-family: sans-serif;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    text-align: left;
  }
  h1 {
    font-size: 72px;
    font-weight: 800;
  }
  h2 {
    font-size: 48px;
    font-weight: 800;
  }
  h3 {
    font-size: 40px;
    font-weight: 800;
  }
  h4 {
    font-size: 28px;
    font-weight: 700;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
  }
  h6 {
    font-size: 20px;
    font-weight: 500;
  }
  p {
    font-size: 18px;
    font-weight: 400;
  }
`

export default GlobalStyle;