import React from 'react';
import styled from 'styled-components';

import logo from '../assets/logos/white-logo.png';
import facebook from '../assets/icons/facebook.png';
import instagram from '../assets/icons/instagram.png';

import Logo from './Logo';
import SectionBlock from './containers/SectionBlock';
import FooterNavbar from '../components/FooterNavbar';
import {Col, Row} from 'react-bootstrap';

const FooterContainer = styled.div`
  background-color: ${props => props.theme.colors.darkGreen};
`

const FooterBlock = styled(SectionBlock)`
  color: white;
  text-align: center;
  padding-top: ${props => props.theme.padding.section.large};
  padding-bottom: ${props => props.theme.padding.section.large};
`

const SocialLinksRow = styled(Row)`
  margin-top: ${props => props.theme.padding.item.standard};
  margin-bottom: ${props => props.theme.padding.item.standard};
  justify-content: center;
`

const BottomNav = styled.div`
  border-top-width: 1px;
  border-top-color: white;
  border-top-style: solid;
  padding-top: ${props => props.theme.padding.mini};
  margin-top: ${props => props.theme.padding.standard};
  display: flex;
  justify-content: space-between;
`

export default function Footer() {
  return (
    <FooterContainer>
      <FooterBlock>
        <Logo alt={'logo'} src={logo}/>

        <SocialLinksRow>
          <Col xs={'auto'}>
            <a href="https://www.facebook.com/uci180dc" target="_blank" rel="noreferrer">
              <img src={facebook} alt={'Facebook'}/>
            </a>
          </Col>
          <Col xs={'auto'}>
            <a href="https://www.instagram.com/180dcuci/" target="_blank" rel="noreferrer">
              <img src={instagram} alt={'Instagram'}/>
            </a>
          </Col>
        </SocialLinksRow>

        <BottomNav>
          <p>
            Copyright 180DC UC Irvine - All rights reserved
            <br/>
            Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a
            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          </p>
          <FooterNavbar/>
        </BottomNav>
      </FooterBlock>
    </FooterContainer>
  )
}
