import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Title, Link } from 'react-head';
import {Col, Row} from 'react-bootstrap';

import Theme from '../styles/Theme';
import GlobalStyle from '../styles/GlobalStyle';

import Header from './Header';
import Footer from './Footer';
import {ButtonA, ButtonLink} from './Button';
import SectionBlock from './containers/SectionBlock';
import useLatestApplicationInfo from '../hooks/useLatestApplicationInfo';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import waves from '../assets/banners/ocean.jpg';
import favicon from '../images/favicon.png';
import GeometricBackgroundWrapper from './containers/GeometricBackgroundWrapper';
import useContactPage from '../hooks/useContactPage';

library.add(fab, fas);

export default function Layout({ location, children }) {

  const applicationInfo = useLatestApplicationInfo();
  const { helperText } = useContactPage();

  return (
    <>
      <Title>{getPageTitle()}</Title>
      <Link rel={'icon'} href={favicon} />
      <GlobalStyle/>
      <ThemeProvider theme={Theme}>
        { getHeader() }
        { children }
        <Footer/>
      </ThemeProvider>
    </>
  )

  function getHeader() {
    if (location.pathname === '/') {
      return (
        <Header
          fullscreen
          parallax
          dim
          textAlign={'center'}
          justify={'center'}
          headerStyle={'dark'}
          backgroundImage={waves}
          backgroundSize={'cover'}
        >
          <h1>180 Degrees Consulting</h1>
          <h3>UC Irvine</h3>
          <p>We provide extremely affordable high-quality consulting
            for socially-minded non-profits in Orange County.
          </p>
          <br/>
          <div>
            <ButtonLink to={'/contact/'} large>Contact Us</ButtonLink>
          </div>
        </Header>
      )
    } else {
      let headerContent;
      let geometricColor;
      if (location.pathname === '/about/' || location.pathname === '/about') {
        geometricColor = Theme.colors.lightBlue025;
        headerContent = (
          <SectionBlock>
            <h1>About us</h1>
          </SectionBlock>
        )
      } else if (location.pathname === '/services/'  || location.pathname === '/services') {
        geometricColor = Theme.colors.brightLightGreen;
        headerContent = (
          <SectionBlock>
            <h1>Our services</h1>
          </SectionBlock>
        )
      } else if (location.pathname === '/join/' || location.pathname === '/join') {
        geometricColor = Theme.colors.lightPink;
        headerContent = (
          <SectionBlock>
            <Row style={{height: '35vh', alignItems: 'center'}}>
              <Col>
                <h1>Join us</h1>
                <br/>
                <p>
                  {applicationInfo.status}
                </p>
                <br/>
                <ButtonA href={applicationInfo.url} large>Apply Now</ButtonA>
              </Col>
            </Row>
          </SectionBlock>
        )
      } else if (location.pathname === '/contact/' || location.pathname === '/contact') {
        geometricColor = 'transparent';
        headerContent = (
          <SectionBlock>
            <h1>Contact us</h1>
            <br/>
            <h6>{helperText.helperText}</h6>
          </SectionBlock>
        )
      }
      return (
        <GeometricBackgroundWrapper
          shape={'circle'}
          color={geometricColor}
          size={'550px'}
          top={'20vh'}
          left={'50vw'}
        >
          <Header
            headerStyle={'light'}
          >
            {headerContent}
          </Header>
        </GeometricBackgroundWrapper>
      )
    }
  }

  function getPageTitle() {
    const path = location.pathname.replace('/', '');
    const capitalizedPath = path.charAt(0).toUpperCase() + path.slice(1);
    const basicTitle = '180 Degrees Consulting UC Irvine';
    const titleSuffix = capitalizedPath !== '' ? ` | ${capitalizedPath}` : '';
    const fullTitle = basicTitle + titleSuffix;
    return fullTitle
  }
}

