import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

import styled from 'styled-components';

const StyledNavLink = styled(Link)`
  color: white;
  padding: 10px;
  
  &:hover {
    color: #eeeeee;
    text-decoration: none;
  }
`

export default function HeaderNavbar() {

  return (
    <Navbar className="navbar-light" expand="md">
      <Navbar.Toggle/>
      <Navbar.Collapse id="white-text basic-navbar-nav">
        <Nav className="navbar-nav ml-auto">
          <StyledNavLink href="/about">About</StyledNavLink>
          <StyledNavLink href="/services">Services</StyledNavLink>
          <StyledNavLink href="/join">Join</StyledNavLink>
          <StyledNavLink href="/contact">Contact us</StyledNavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}