import { useStaticQuery, graphql } from 'gatsby';

const applicationInfoQuery = graphql`
  query {
    allContentfulJoinPage(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        applicationStatus {
          url
          status
        }
      }
    }
  } 
`

export default function useLatestApplicationInfo() {
  const latestApplicationInfoNode = useStaticQuery(applicationInfoQuery).allContentfulJoinPage.nodes[0].applicationStatus;
  return latestApplicationInfoNode;
}