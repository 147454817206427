import React from 'react';
import styled, {keyframes} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';

const bounce = keyframes`
  0%, 10%, 25%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
`;

const fadeIn = keyframes`
  0%, 75% {
    opacity: 0%
  }
  100% {
    opacity: 100%
  }
`

const BouncingIcon = styled(FontAwesomeIcon)`
  -moz-animation: ${bounce} 3s infinite, ${fadeIn} 3s;
  -webkit-animation: ${bounce} 3s infinite, ${fadeIn} 3s;
  animation: ${bounce} 3s infinite, ${fadeIn} 3s;
  color: white;
`;

export default function AnimatedScrollArrow() {
  return (
    <BouncingIcon icon={faChevronDown} size={'3x'} />
  )
};