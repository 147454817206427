import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {ButtonLink} from './Button';
import logo from '../assets/logos/black-logo.png'
import whiteLogo from '../assets/logos/white-logo.png';

const NavbarBlock = styled(Container)`
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
`

const StyledNavLink = styled(Link)`
  color: ${props => props.navbarstyle === 'light' ? props.theme.colors.dark : 'white'};
  transition: background-color 100ms;
  padding: 10px;
  
  &:hover {
    text-decoration: none;
    color: ${props => props.navbarstyle === 'light' ? props.theme.colors.darkGreen : 'white'};
    background-color: ${props => props.navbarstyle === 'light' ? props.theme.colors.green005 : 'rgba(255,255,255,0.1)'};
  }
`

const SpecialNavLink = styled(ButtonLink)`
  padding: 10px !important;
  margin-left: 10px;
`

const Logo = styled.img`
  width: 150px;
  height: 60px;
`

export default function HeaderNavbar({navbarstyle}) {
  return (
    <NavbarBlock>
      <Navbar variant={navbarstyle} expand={'md'}>
        <Container>
          <Navbar.Brand>
            <Link to="/">
              {navbarstyle === 'light' ? <Logo src={logo} alt={'logo'}/> : <Logo src={whiteLogo} alt={'logo'}/>}
            </Link>
          </Navbar.Brand>

          <div>
            <Navbar.Toggle/>

            <Navbar.Collapse >
              <Nav className={'ml-auto'}>
                <StyledNavLink navbarstyle={navbarstyle} to={'/about/'}>About us</StyledNavLink>
                <StyledNavLink navbarstyle={navbarstyle} to={'/services/'}>Our services</StyledNavLink>
                <StyledNavLink navbarstyle={navbarstyle} to={'/join/'}>Join us</StyledNavLink>
                <SpecialNavLink navbarstyle={navbarstyle} to={'/contact/'}>Contact us</SpecialNavLink>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </NavbarBlock>
  )
}
