import {graphql, useStaticQuery} from 'gatsby';

const contactPageQuery = graphql`
  query {
    allContentfulContactPage(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        helperText {
          helperText 
        }
        forServices {
          name
          position
          email
        }
        forStudents {
          name
          position
          email
        } 
      }
    }
  }
`

export default function useContactPage() {
  const contactPageLatestNode = useStaticQuery(contactPageQuery).allContentfulContactPage.nodes[0];
  return contactPageLatestNode;
}