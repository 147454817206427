import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Circle = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  z-index: -2;
  background-color: ${props => props.color};
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
`

const Container = styled.div`
  position: relative;
`

function GeometricBackgroundWrapper({shape, color, size, children, top, left}) {
  if (shape === 'circle') {
    return (
      <Container>
        {children}
        <Circle size={size} color={color} top={top} left={left} />
      </Container>
    )
  } else {
    return children;
  }
}

GeometricBackgroundWrapper.propTypes = {
  shape: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string
}

export default GeometricBackgroundWrapper;
