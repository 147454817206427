const Theme = {
  colors: {
    compassGreen: '#6fab20',
    accentCompassGreen: '#67a517',
    darkGreen: '#3f7b00',
    lightGreen: '#efffb0',
    brightLightGreen: '#ddffb1',
    green005: 'rgba(111, 171, 32, 0.05)',
    green025: 'rgba(111, 171, 32, 0.25)',
    dark: '#343a40',
    light: '#f8f9fa',
    lightBlue: '#C0DAE5',
    lightBlue025: 'rgba(192, 218, 229, 0.25)',
    blue: '#92B8F0',
    lightPink: '#FCEDF5'
  },
  padding: {
    section: {
      large: '75px',
      standard: '50px',
      small: '30px'
    },
    item: {
      huge: '4em',
      large: '2em',
      standard: '1em',
      small: '0.8em'
    }
  },
  font: {
    standard: '18px',
    large: '20px'
  },
  shadow: {
    standard: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    hard: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  }
}

export default Theme;