import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeaderNavbar from './HeaderNavbar';

import SectionBlock from './containers/SectionBlock';
import AnimatedScrollArrow from './AnimatedScrollArrow';

const Header = ({fullscreen, parallax, dim, headerStyle, backgroundImage, backgroundSize, justify, textAlign, children}) => {
  return (
    <HeaderContainer
      fullscreen={fullscreen}
      parallax={parallax}
      dim={dim}
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
    >
      <HeaderNavbar
        navbarstyle={headerStyle}
      />

      <HeaderSectionBlock
        justify={justify}
      >
        <HeaderContent
          textAlign={textAlign}
          headerStyle={headerStyle}
        >
          {children}
        </HeaderContent>
      </HeaderSectionBlock>

      { fullscreen ?
        <ArrowContainer>
          <AnimatedScrollArrow/>
        </ArrowContainer>
        :
        <></>
      }
    </HeaderContainer>
  )
}

Header.propTypes = {
  fullscreen: PropTypes.bool,
  parallax: PropTypes.bool,
  dim: PropTypes.bool,
  headerStyle: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundSize: PropTypes.string,
  justify: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.any
}

export default Header;

// ---------- Hero Header Details ------------

const HeaderSectionBlock = styled(SectionBlock)`
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  height: 80%;
`

const HeaderContent = styled.div`
  color: ${props => props.headerStyle === 'dark' ? 'white' : props.theme.colors.dark};
  & > * {
    text-align: ${props => props.textAlign};
  }
`

const HeaderContainer = styled.div`
  height: ${props => props.fullscreen ? '100vh' : 'auto'};
  background-image: url(${props => props.backgroundImage});
  background-size: ${props => props.backgroundSize};
  background-attachment: ${props => props.parallax ? 'fixed' : 'inherit'};
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${props => props.dim ? 'rgb(125,125,125)' : 'transparent'};
  background-blend-mode: multiply;
`

const ArrowContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
`
